@import url(https://fonts.googleapis.com/css2?family=My+Soul&display=swap);
.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading .ant-spin {
  color: #E05735;
}

.project-image::-webkit-file-upload-button {
  visibility: hidden;
}

.project-image::before {
  content: 'Click to Upload';
  display: block;
  background: linear-gradient(to bottom, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 8px;
  padding: 7px 0px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 500;
  font-size: 11pt;
  text-align: center;
  /* margin-right: 10px; */

}

.project-image:hover::before {
  border-color: black;
}

.project-image:active::before {
  background: -webkit-linear-gradient(to bottom, #e3e3e3, #f9f9f9);
}

.project-image {
  width: auto;
}

.room-user {
  padding: 15px;
}

.room-audio {
  padding: 6px;
}

.shallow_gray {
  background-color: #f3f7dc;
}

.selected_user_rows {
  background-color: #E6F2FF;
}

.project-statistics .ant-page-header-heading {
  justify-content: space-evenly;
}

.empty-data .ant-empty {
  padding-top: 10%;
}

.site-back-top-basic {
  color: rgba(64, 64, 64, 0.6);
}

.avatar-bg .avatar-status .ant-avatar {
  background-color: #528add;
}

.add-label-form .ant-space-item{
  width: 100%;
}

.ant-pagination-options {
  display: block !important;
}
